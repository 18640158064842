@import "variables"


/*** Home ***/

.nav-buffer
  display: inline
  visibility: hidden
  height: 0
  margin: 0
  border: none
  padding: 0
  z-index: -1

.home-section
  text-align: center
  .greeting
    .intro
      font-size: 1.95rem
      font-weight: 400
      text-align: center
      margin: 0 0 0.8em
      transition: margin 500ms linear
    .name
      font-size: 4rem
      font-weight: 600
      color: $color-light
      background: $color-primary-darker
      display: inline
      padding: 0 0.5em
      letter-spacing: 2px
    .followup
      margin: 1.25em 0 0.25em
      p
        font-size: 1.95rem
        font-weight: 400
        margin: 0.2em 0
  .headshot-container
    height: 20em
    margin: 1.25em 0
    display: flex
    align-items: center
    justify-content: center
    img
      height: 95%
      border-radius: 1em
  .highlights
    .highlight
      font-size: 1.4rem
      margin: 0

@media (min-width: 500px)
  .home-section
    .nav-buffer
      display: block
      visibility: visible
      height: $navbar-height
    .greeting
      .intro
        margin-top: 0.8em

/*** Experience ***/

.job
  .job-title
    font-size: 1.6rem
    font-weight: 500
    color: $color-primary-darker
    margin: 1.25em 0 0.25em
    display: block
    white-space: pre-wrap
  .col-logo-content
    display: grid
    grid-template: 1fr / 59% 40%
    grid-gap: 0.35em
    .logo-container
      order: 2
      display: flex
      align-items: center
      justify-content: center
      .job-logo
        max-width: 85%
        max-height: 5em
        margin: 0 auto
        display: block
      .poker-logos
        animation: 20s poker-logo-slideshow 0s linear infinite forwards
        opacity: 1
        content: url("https://storage.googleapis.com/tb-public-misc/logos/pokerstars.jpg")
      .wide-logo
        max-width: 250px
        transition: max-width 300ms linear
      .egencia-eg-logos
        width: 100%
        animation: 8s egencia-eg-slideshow 0s linear infinite forwards
        opacity: 1
        content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
      .egencia-gbt-logos
        animation: 20s egencia-gbt-slideshow 0s linear infinite forwards
        opacity: 1
        content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
    .multi-logo
      display: flex
      justify-content: space-between
      //align-items: space-between
      margin: 0 0.2em
      max-width: 35%

    .job-details
      order: 1
      display: flex
      flex-direction: column
      margin: 0 0.1em
      span
        margin: 0.15em 0
      .job-company-name
        font-size: 1.25rem
        margin: 0.15em 0
        .job-company-addendum
          font-size: 1.1rem

  .job-content
    .job-accomplishments
      margin: 1em 0
      li
        list-style: square
        margin: 1em 0


@media (min-width: 300px)
  .experience-section
    .jobs-container
      .job
        .logo-container
          .wide-logo
            max-width: 150px
            //border: 1px dashed red

@media (min-width: 490px)
  .experience-section
    .jobs-container
      .job
        .logo-container
          .wide-logo
            max-width: 190px
            //border: 1px solid red

@media (min-width: 600px)
  .experience-section
    .jobs-container
      .job
        .job-title
          font-size: 1.8rem
          white-space: initial

@media (min-width: 660px)
  .experience-section
    .jobs-container
      .job
        .logo-container
          .wide-logo
            max-width: 220px
            border-color: transparent

.logo-container
  padding: 0.2em
  display: flex
  align-items: center
  justify-content: center
  transition: background
  outline: 1px dotted transparent
  img
    display: block
  .cr-logo
    background: rgb(19,34,58)
    padding: 0.5em
    border-radius: 10px
.logo-container:hover
  background: lightgray

@media (min-width: 500px)
  .logo-container
    a
      padding: 0.25em
    a:hover
      background: lightgray
  .logo-container:hover
    background: unset

.icon-plus-text
  display: grid
  grid-template-rows: 1fr
  grid-template-columns: minmax(20px, 0.65fr) 19fr
  grid-column-gap: 4px
  grid-row-gap: 15px
  align-items: center
  margin: 0 0 0.25em
  font-size: 0.85rem
  //border: 1px solid red
  .text,
  .icon
    //border: 1px solid blue
    margin: 0
  //.text
  //  margin-bottom: 1em

@keyframes poker-logo-slideshow
  0%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/pokerstars.jpg")
  5%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/pokerstars.jpg")
  45%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/pokerstars.jpg")
  50%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/pokerstars.jpg")
  51%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/fulltilt.png")
  56%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/fulltilt.png")
  95%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/fulltilt.png")
  100%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/fulltilt.png")

@keyframes egencia-eg-slideshow
  0%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  5%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  45%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  50%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  51%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/expedia-group.svg")
  56%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/expedia-group.svg")
  95%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/expedia-group.svg")
  100%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/expedia-group.svg")

@keyframes egencia-gbt-slideshow
  0%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  5%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  45%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  50%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/egencia.svg")
  51%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/amex-gbt-stacked.svg")
  56%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/amex-gbt-stacked.svg")
  95%
    opacity: 1
    content: url("https://storage.googleapis.com/tb-public-misc/logos/amex-gbt-stacked.svg")
  100%
    opacity: 0
    content: url("https://storage.googleapis.com/tb-public-misc/logos/amex-gbt-stacked.svg")


/*** Projects ***/

.project
  .proj-link
    outline: 1px dotted transparent
  .proj-link:active,
  .proj-link:focus
    display: block
    outline: 1px dotted $color-border
  .proj-title-section
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0.5em 0
    .proj-name
      font-size: 1.8rem
      font-weight: 500
      color: $color-primary-darker
      white-space: pre-wrap
    .proj-links
      margin: 0 0.2em
      display: flex
      align-items: flex-end
      justify-content: flex-end
      .proj-link-icon-container
        padding: 0.15em
        outline: 1px dotted transparent
      .proj-link-icon-container:hover
        background: lightgray
        transition: background 150ms linear
      .icon
        display: block
        height: 1.25em
        width: 1.25em
        max-height: unset
        max-width: unset
        margin: 0 0.25em
  .proj-description-group
    font-size: 1.25rem
    margin: 1em 0 0
    .proj-desc,
    .proj-status-group
      display: block
      margin: 0.5em 0
    .proj-status-group
      display: flex
      img
        filter: invert(25%) sepia(18%) saturate(4453%) hue-rotate(244deg) brightness(90%) contrast(87%)
        margin-right: 0.35em
      .proj-status
        font-size: 1rem
        //font-weight: 500
        margin-bottom: 0
        color: $color-primary-triadic
  .proj-screenshot-container
    display: block
    overflow: hidden
    max-width: 400px
    max-height: 330px
    width: 100%
    margin: 2em auto
    background-color: inherit
    outline: 1px dotted transparent
    box-shadow: 3px 3px 0.35em $color-border-lighter
    transition: width 500ms ease-in-out
    img
      object-fit: cover
  .proj-screenshot-container:hover
    box-shadow: 3px 3px 0.5em $color-border
    transition: box-shadow 250ms linear
  .proj-notes-group
    margin: 1em 0.25em
    line-height: 1.5
    h3
      font-size: 1.25rem
      margin: 1em 0 0.5em
    h3:first-of-type
      margin-top: 0
    .proj-overview
      margin: 0 0 0.75em
    .proj-note.more-notes
      margin: 0
    .more-notes
      visibility: hidden
      height: 0
      margin: 0
      padding: 0
      opacity: 0
      transition: height 300ms ease-in-out, opacity 200ms ease-in-out //, margin 100ms ease-in-out 400ms, padding 100ms ease-in-out 400ms
    .more-notes.expanded
      visibility: visible
      height: auto
      padding: revert
      margin: revert
      opacity: 1
      transition: height 300ms ease-in-out, opacity 200ms ease-in-out //, margin 100ms linear, padding 100ms linear
    .proj-notes
      p
        margin: 0.75em 0
      p:first-of-type
        margin: 0 0 0.25em

  .proj-learn-more
    display: inline
    color: $color-primary-darker
    border: 1px dotted transparent
    margin: 1em 0
  .proj-learn-more:hover
    text-decoration: underline
    cursor: pointer
  .proj-learn-more:active,
  .proj-learn-more:focus
    border: 1px dotted $color-border

  .proj-tech
    margin-top: 2em
    .proj-tech-type-container
      display: flex
      flex-direction: column
      //align-items: center
      align-items: center
      justify-content: center
      margin: 1.5em 0.25em
      border: 1px dashed $color-primary-triadic
      border-radius: 2em
      .proj-tech-type-label
        font-size: 1.2rem
        font-weight: 500
        color: $color-light
        position: relative
        top: -0.75em
        display: inline
        margin: 0
        padding: 0 0.5em
        background: $color-primary-triadic
        border: 1px solid $color-primary-triadic
        border-radius: 10px
        cursor: default
      .proj-tech-list
        width: 100%
        margin: 0 0.25em 1em
        display: grid
        grid-template-rows: 1fr
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr))
        grid-gap: 1em 0.5em
        cursor: default
        .proj-tech-list-item
          display: flex
          flex-direction: column
          align-items: center
          justify-content: space-around
          text-align: center
          .logo-container
            max-width: 3.5em
            max-height: 3em
            padding: 0
          .list-item-label
            font-size: 0.9rem
            margin: 0.25em 0.5em
            padding: 0.25em 0
            cursor: text

.expand-collapse-with-icon
  display: inline-flex
  align-items: center
  .text
    white-space: nowrap
  .svg-as-text
    margin: 0 0 0 0.45em
    // Set svg color to match text color, which is #0647b6 here
    // SVG color conversion tool: https://codepen.io/sosuke/pen/Pjoqqp
    //  -> Prefix with _brightness(0) saturate(100%)_ to ensure all SVGs start completely black.
    filter: brightness(0) saturate(100%) invert(13%) sepia(82%) saturate(4645%) hue-rotate(215deg) brightness(92%) contrast(95%)

.svg-as-text  // For icons which appear as text elements
  font-size: 0.67em


@media (min-width: 500px)
  .project
    .proj-tech
      .proj-tech-type-container
        .proj-tech-list
          .proj-tech-list-item
            .list-item-label
              font-size: 1rem

@media (min-width: 650px)
  .project-section
    .project
      .proj-tech
        display: grid
        grid-template-rows: 1fr
        grid-template-columns: minmax(290px, 1fr) minmax(290px, 1fr)
        .proj-tech-type-container.wide
          grid-column: span 2
          .proj-tech-list
            padding: 0 1.5em

@media (min-width: 720px)
  .project-section
    .project
      .proj-screenshot-notes
        margin: 2em 0
        display: grid
        grid-row-gap: 1em
        .proj-screenshot-container
          margin: 0
          grid-area: screenshot
          max-width: unset
          max-height: unset
        .proj-notes-group
          margin: 0
          padding: 0 0.25em
          grid-area: notes
          .proj-overview
            margin-top: 0
      .proj-screenshot-notes.tall
        grid-template-rows: repeat(auto-fit, 1fr)
        grid-template-columns: 35% 65%
        grid-template-areas: "screenshot notes"
        .proj-notes-group
          padding-left: 1em
      .proj-screenshot-notes.wide
        grid-template-rows: repeat(auto-fit, 1fr)
        grid-template-columns: repeat(auto-fit, 1fr)
        grid-template-areas: "screenshot" "notes"
        .proj-screenshot-container.wide
          margin: 0 auto
          max-width: 65%
          .screenshot
            margin: 0 auto

@media (min-width: 800px)
  .project-section
    .project
      .proj-tech
        max-width: 800px
        margin-left: auto
        margin-right: auto


/*** Certifications ***/

.certs-container
  margin: 0 15%
  .cert
    max-width: 600px
    min-height: 150px
    background: $color-background
    margin: 0 0 1.5em 0
    border: 1px solid $color-border
    border-radius: 10px
    padding: 0.75em
    .cert-title
      font-size: 1.25rem
      font-weight: 500
      text-align: center
      line-height: 1.3
      color: $color-primary-darker
      margin: 0 0 0.75em
    .cert-content
      display: grid
      grid-template: 1fr / 25% 75%
      grid-column-gap: 10px
      .logo-container
        padding: 0.2em
        .cert-logo
          display: block
          max-height: 3em
      .cert-details
        width: 100%
        height: auto
        margin: 0
        display: flex
        flex-direction: column
        justify-content: center


@media (min-width: 500px)
  .icon-plus-text
    font-size: 1rem
  .certification-section
    .certs-container
      margin: 0 5em
      .cert
        .cert-title
          font-size: 1.5rem

@media (min-width: 620px)
  .certification-section
    .certs-container
      display: grid
      padding: 0
      margin: 0
      grid-template-rows: repeat(auto-fill, minmax(150px, 1fr))
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr))
      grid-gap: 0.5em
      .cert
        margin: 0
        padding: 1em
        display: grid
        grid-template: 1fr / 1fr
        grid-gap: 1em 0
        .cert-title
          margin: 0
          text-align: center
          display: flex
          flex-direction: column
          justify-content: center
        .cert-content
          height: auto
          width: 100%

@media (min-width: 720px)
  .certification-section
    .certs-container
      grid-gap: 1em


/*** Education ***/

.degree
  display: flex
  flex-direction: column
  align-items: flex-start
  .degree-title,
  .degree-content
    width: 100%
    max-width: 550px
  .degree-title
    font-size: 1.25rem
    font-weight: 500
    line-height: 1.3
    color: $color-primary-darker
    margin: 0 0 0.5em
  .degree-content
    display: grid
    grid-template: 1fr / 25% 75%
    //grid-gap: 1em
    .logo-container
      margin: 0 1em 0 0
      padding: 0.2em
      .degree-logo
        display: block
        width: 100%
    .degree-details
      display: flex
      flex-direction: column
      justify-content: center
      margin: 0

@media (min-width: 500px)
  .education-section
    .degree
      .degree-title
        font-size: 1.5rem
      .logo-container
        max-width: 200px
        max-height: 200px
        margin: 0 0.5em 0 0

.icon
  max-width: 1.15em
  max-height: 1.15em


/*** Contact ***/

.contact-section
  .contacts
    //display: grid
    //grid-template: repeat(auto-fit, 80px) / repeat(auto-fit, 80px)
    display: flex
    align-content: space-between
    max-width: 500px
    margin: 0 auto
    .contact-group
      margin: 0 auto
      border-radius: 0.625em
      background: $color-background
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      .contact-link
        color: inherit
        border: 1px solid $color-border
        border-radius: 0.625em
        padding: 1em
        height: 100%
        text-decoration: none
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .icon
          width: 80px
          max-width: 50%
          max-height: 50%
          margin: 0.5em 0
      .contact-link:hover
        background: white
        border: 1px solid $color-primary-darker
        .icon
          background: white
      .contact-link:focus,
      .contact-link:active
        outline: 1px dotted $color-border-darker
        background: $color-background
