@import variables


.header-container
  background: $color-background
footer
  background: $color-background-alt

.separator-horiz
  height: 1px
  width: 50%
  margin: 1.25em auto
  background: linear-gradient(90deg, $color-background, $color-dark, $color-background)
