@use "sass:math"

/*** Fonts ***/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Source+Code+Pro:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap')
$font-stack-primary: 'Roboto Slab', serif
$font-stack-secondary: 'Roboto', sans-serif
$font-stack-mono: 'Roboto Mono', 'Source Code Pro', 'Courier New', monospace


/*** Color Palette ***/

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors
$color-primary: #0067d5
$color-primary-darker: #0647b6
$color-primary-lighter-1: #0079e7
$color-primary-lighter-2: #008bfb
$color-primary-lighter-3: #009aff
$color-primary-lighter-4: #2fa9ff
$color-primary-lighter-5: #5bb9ff
$color-primary-lighter-6: #8dcdff
$color-primary-lighter-7: #bae0ff
$color-primary-lighter-8: rgb(213, 227, 238)

$color-primary-complementary: #d56e00
$color-primary-complementary-darker: #cb5700
$color-primary-complementary-lighter-1: #db7d03
$color-primary-complementary-lighter-2: #e18b09
$color-primary-complementary-lighter-3: #e5960e
$color-primary-complementary-lighter-4: #e8a52b
$color-primary-complementary-lighter-5: #ebb44f
$color-primary-complementary-lighter-6: #f0ca81
$color-primary-complementary-lighter-7: #f6deb2
$color-primary-complementary-lighter-8: #fbf2e0

$color-primary-triadic: #673a90
$color-primary-triadic-lighter: #654e85
$color-primary-triadic-1: #6e00d5
$color-primary-triadic-2: #d50067
$color-primary-alt-1: #00d1d5
$color-primary-alt-2: #0400d5

$color-secondary: #bdbdbd  // #ecebe7
$color-secondary-lighter: #e0e0e0
$color-secondary-darker: #828282

$color-light: #f8f8f8
$color-light-alt: #d1d7e0
$color-lightest: #f2f2f2
$color-dark: #171717
$color-dark-lighter: #292929
$color-dark-lightest: #222222
//$color-background: rgb(240,240,240)
//$color-background-alt: rgb(225,225,225)
$color-background: $color-light
$color-background-alt: rgb(240, 240, 240)
$color-nav: $color-primary-darker

$color-border: rgba(151, 154, 160, 1)  // #979aa0
$color-border-lighter: rgba(151, 154, 160, 0.6)
$color-border-lightest: rgba(151, 154, 160, 0.15)
$color-border-darker: #696c72

$color-link: $color-primary-complementary-darker
$color-link-visited: $color-primary-complementary-darker


/*** Favicon ***/
// Base icon from: https://favicon.io/favicon-generator/
// Then uploaded to: https://realfavicongenerator.net/

$color-favicon-background: $color-primary-darker  // #0647b6
$color-favicon-foreground: #ffffff
$favicon-font: 'Caveat Brush'
$favicon-font-size: 100


/*** Page Layout ***/

$app-max-width: 1080px


/*** Nav ***/

$navbar-height: 43px
$icon-hamburger-height: 36px
$icon-hamburger-width: math.div($icon-hamburger-height, 3) * 4
