@import "variables"


/*** Navigation ***/

.navbar
  position: inherit
  z-index: 100
  .links-container.mobile-hidden
    display: none
  .links-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    a
      margin: 0.4em
      padding: 0.1em
      font-size: 2.75rem

.nav-expanded
  height: 100vh
  margin: 0
  padding: 0

.mobile-hidden
  display: none

@media (min-width: 500px)
  .nav-expanded
    height: unset
  .mobile-hidden
    display: unset
  .content-container
    //padding-top: 50px
    background: $color-background
  .navbar
    opacity: 1
    position: fixed
    width: 100%
    max-width: $app-max-width
    margin: 0
    border: 2px solid $color-nav
    .nav-hamburger
      display: none
    .links-container.mobile-hidden
      display: flex
    .links-container
      height: unset
      position: sticky
      top: 0
      flex-direction: row
      justify-content: space-evenly
      background: $color-nav
      a
        font-size: 1rem
        color: $color-light
        background: $color-nav
        margin: 0 0.1em
        padding: 0.5em 0.6em
        border-bottom: 3px solid $color-nav
        text-decoration: none
        transition: border-bottom-color 200ms ease-in-out
      a:hover
        border-bottom-color: $color-light
      .active
        border-bottom-color: $color-light

  // Hide 'Education' from static nav until there's enough viewport width
  .nav-education
    display: none

@media (min-width: 620px)
  // Viewport is now wide enough to show 'Education' in the static navbar
  .nav-education
    display: unset

@media (min-width: 700px)
  .navbar
    .links-container
      justify-content: center
      a
        margin: 0 0.5em
