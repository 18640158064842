@import "variables"


/*** Hamburger styles & transitions ***/

.nav-hamburger
  height: $icon-hamburger-height
  width: $icon-hamburger-width
  margin: 0.5em
  border: none
  padding: 0
  position: relative
  display: inline
  background: transparent
  outline: 1px dotted transparent
  opacity: 1
  cursor: pointer
  span
    display: block
    position: absolute
    height: 20%
    width: 100%
    background: $color-dark
    border: none
    border-radius: 0.5em
    opacity: 1
    left: 0
    -webkit-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)
    -webkit-transition: 250ms ease-in-out
    -moz-transition: 250ms ease-in-out
    -o-transition: 250ms ease-in-out
    transition: 250ms ease-in-out
  span:nth-child(1)
    top: 0
  span:nth-child(2),
  span:nth-child(3)
    top: 40%
  span:nth-child(4)
    top: 80%

.nav-hamburger.open
  span:nth-child(1),
  span:nth-child(4)
    top: 1.125em
    left: 50%
    width: 0
  span:nth-child(2)
    -webkit-transform: rotate(45deg)
    -moz-transform: rotate(45deg)
    -o-transform: rotate(45deg)
    transform: rotate(45deg)
  span:nth-child(3)
    -webkit-transform: rotate(-45deg)
    -moz-transform: rotate(-45deg)
    -o-transform: rotate(-45deg)
    transform: rotate(-45deg)

.nav-hamburger:focus,
.nav-hamburger:active,
.nav-hamburger:hover
  border: inherit
  background: inherit
  color: inherit
  outline: inherit

.nav-hamburger:focus
  outline: 1px dotted $color-border
  span
    background-color: $color-dark-lighter

//.nav-hamburger:active
//  span:nth-child(1)
//    transition: 150ms ease-in 100ms
//    transform: translateY(2px)
//  span:nth-child(4)
//    transition: 150ms ease-in 100ms
//    transform: translateY(-2px)

.nav-hamburger:hover
  span
    animation: 300ms hamburger-color-change 0ms ease-in-out forwards

@keyframes hamburger-color-change
  0%
    background-color: $color-dark
  50%
    background-color: $color-dark-lightest
  100%
    background-color: $color-dark-lighter
