@import "variables"


/*** HTML Tags ***/

*,
*::before,
*::after
  box-sizing: border-box

body
  font-size: 16px
  font-family: $font-stack-primary
  font-variant: slashed-zero
  color: $color-dark
  line-height: 1.3
  letter-spacing: .5px
  text-align: left
  margin: 0 auto
  max-width: $app-max-width
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: $font-stack-mono

h2,
h3,
h4
  margin: 0
  text-align: left
  cursor: default

h1
  font-size: 3.2rem
  margin: 0 0 0.6em
  text-align: center
  cursor: default

h2
  font-size: 2rem
  margin: 0.25em 0

h3
  font-size: 1.4rem
  margin: 1em 0

p
  margin: 0.75em 0.25em
  font-size: 1rem
  font-weight: 400

strong
  display: inline
  font-weight: inherit
  color: $color-primary-darker

img
  display: inline-block
  max-width: 100%
  max-height: 100%

a
  color: $color-link
  text-decoration: underline
  text-decoration-width: 1px
  outline: 1px dotted transparent
a:visited
  color: $color-link-visited
a:hover
  text-decoration: none
a:focus,
a:active
  outline-color: $color-border

li
  line-height: 1.5


/*** Page Layout ***/

.app-container
  background: $color-background
  margin: 0 auto
  max-width: $app-max-width
  section
    padding: 3em 0.75em
    transition: padding 650ms ease-in-out
    article
      margin: 2.5em 0
    article:last-of-type
      margin-bottom: 0
  section:nth-of-type(odd)
    background: $color-background
  section:nth-of-type(even)
    background: $color-background-alt
  section:first-of-type
    padding-top: 0
  section:last-of-type
    //padding-bottom: 0
    background: $color-background-alt

@media (min-width: 500px)
  .app-container
    section
      padding: 3em 0.9em

@media (min-width: 600px)
  .app-container
    section
      padding: 3em 1em

@media (min-width: 800px)
  .app-container
    section
      padding: 3em 1.5em

@media (min-width: 970px)
  .app-container
    section
      padding: 3em 2.5em

.sr-only  // for screen readers
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  padding: 0
  overflow: hidden
  clip: rect(0,0,0,0)
  border: 0

.footer-container.mobile-hidden
  display: none
.footer-container
  display: block
  font-size: 0.8rem
  text-align: center
  padding: 1.25em 0.25em
  background: $color-background-alt
  white-space: pre-wrap
  .app-logo
    margin-left: 0.25em
    height: 1.2em

@media (min-width: 420px)
  .footer-container
    white-space: initial

@media (prefers-reduced-motion: no-preference)
  .app-logo
    animation: spinning-bread infinite 20s linear
  html
    scroll-behavior: smooth

@keyframes spinning-bread
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.hidden
  display: none
  opacity: 0

.icon
  max-width: 1.1em
  max-height: 1.1em
  margin-right: 0.35em
  outline: 1px dotted transparent
